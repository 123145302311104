<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 text-capitalize">
          {{
            (isAdd ? $t("actions.add") : $t("actions.edit")) +
            " " +
            $tc("municipality.title", 1)
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm">

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$t('municipality.name')"
              label-for="description">
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Acronym -->
          <validation-provider
            #default="validationContext"
            name="acronym"
            required="true">
            <b-form-group
              class="text-capitalize"
              :label="$t('municipality.acronym')"
              label-for="acronym">
              <b-form-input
                id="acronym"
                v-model="itemData.acronym"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Estado -->
          <validation-provider
            #default="validationContext"
            name="id_region"
            required="true">
            <b-form-group
              class="text-capitalize"
              :label="$tc('region.title', 1)"
              label-for="id_region"
              :state="getValidationState(validationContext)">
              <v-select
                v-model="itemData.id_region"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="regionOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_region"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit">
              {{ isAdd ? $t("actions.add") : $t("actions.edit") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              class="text-capitalize"
              @click="hide">
              {{ $t("actions.cancel") }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, positive,
} from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    regionOptions: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    }
  },
  setup(props, { emit }) {
    const blankData = {
      name: '',
      acronym: '',
      id_region: null
    }

    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData))
    }

    const onSubmit = () => {
      let dispatch;

      if (props.isAdd) {
        dispatch = store.dispatch('municipality/add', itemData.value);
      }
      else {
        const req = {
          id: itemData.value.id,
          params: {
            name: itemData.value.name,
            acronym: itemData.value.acronym,
            id_region: itemData.value.id_region.value
          }
        }

        dispatch = store.dispatch('municipality/edit', req)
      }

      dispatch
        .then(response => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            emit('refetch-data')
            emit('update:is-sidebar-active', false)
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        }).catch(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit
        itemData.value.id_region = props.regionOptions.find(region => { return region.value ==  props.itemEdit.id_region})
        console.log(props.itemEdit, props.regionOptions, itemData.value)
      }
    }

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
    }
  },
}
</script>
